import SiteLink from 'components/shared/sitelink';
import Image from 'components/shared/image';
import { addLocaleToHref } from 'lib/utils/helpers';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import LocaleSelector from '../localeSelector';
import KlaviyoComponent from '../klaviyo';
import Social from '../social';
import styles from './footer.module.scss';

const Footer = ({ locale, globalModules, pageData, is404 }) => {
  const { footerMenu, locales, social, footerMenuSecondary, footerIcons, newsletter } =
    globalModules?.layout;
  const {
    settings: { localesWithWebshop },
    firstcountryselectorlocales,
  } = globalModules;
  const router = useRouter();
  const extractedCountries = firstcountryselectorlocales
    ? Object.values(firstcountryselectorlocales)
    : [];
  const allLocales = extractedCountries.map(country => country.countryList).flat();

  const getExternalLinkWithParams = externalLink => {
    if (externalLink?.includes('salon-finder')) {
      // lcl means locale
      const [lcl, slug] = externalLink?.split('/').filter(Boolean);

      return addLocaleToHref(`/${slug}`, lcl, router);
    }

    return externalLink;
  };

  return (
    <footer
      className={classNames(styles.footer, {
        [styles.hasMargin]: !is404,
      })}
    >
      {newsletter && newsletter.newsletter?.formId && (
        <div className={styles.rowNewsletter}>
          <KlaviyoComponent newsletter={newsletter.newsletter} />
        </div>
      )}
      {footerMenu && (
        <ul className={styles.menu}>
          {footerMenu.menus[0].menu.map(({ externalLink, slug, title: linkTitle, pageReload }) => (
            <li key={linkTitle}>
              <SiteLink
                external={externalLink && getExternalLinkWithParams(externalLink)}
                href={addLocaleToHref(slug, locale, router)}
                reloaded={pageReload}
              >
                <p>{linkTitle}</p>
              </SiteLink>
            </li>
          ))}
        </ul>
      )}
      {locales && (
        <div className={styles.rowLocaleSelector}>
          <LocaleSelector locale={locale} allLocales={allLocales} pageData={pageData} />
        </div>
      )}
      <div className={styles.rowSocial}>
        <Social locale={locale} networks={social.menus[0].menu} />
      </div>
      {footerMenuSecondary && (
        <div className={styles.secondary}>
          <div className={styles.title}>{footerMenuSecondary.menus[0].title}</div>
          <ul className={styles.links}>
            {footerMenuSecondary.menus[0].menu.map(({ externalLink, slug, title: linkTitle }) => (
              <li key={linkTitle}>
                <SiteLink
                  external={externalLink && getExternalLinkWithParams(externalLink)}
                  href={addLocaleToHref(slug, locale, router)}
                >
                  <h3>{linkTitle}</h3>
                </SiteLink>
              </li>
            ))}
          </ul>
        </div>
      )}
      {localesWithWebshop?.onOrOff && footerIcons && (
        <div className={styles.rowIcon}>
          {footerIcons.media.map(mediaIcon => (
            <Image
              src={mediaIcon.image.desktop}
              className={styles.icon}
              layout="fill"
              key={mediaIcon.image.desktop.title}
            />
          ))}
        </div>
      )}
    </footer>
  );
};

export default Footer;
