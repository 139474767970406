import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import cx from 'classnames';
import SiteLink from 'components/shared/sitelink';
import Logo from 'components/shared/logo';
import Icon from 'components/shared/icon';
import useCart from 'hooks/shop/useCart';
import MobileMenu from 'components/layout/menuMobile';
import { addLocaleToHref } from 'lib/utils/helpers';
import { useUI, useSettings, useCustomer, useLocalWishlist } from 'hooks';
import HeartIcon from 'components/shared/heart-icon';
import useIsMobile from 'hooks/utils/useIsMobile';
import { getSelectedSalonNo, getStorageCustomerSalonName } from 'lib/shopify';
import AnnouncementBar from '../announcementbar';
import styles from './header.module.scss';
import BelgiumLangSelector from '../../shared/belgium-language-selector';

const Header = ({
  locale,
  menu,
  mobileMenu,
  mobileMenuSecondary,
  globalModules,
  is404,
  pageData,
}) => {
  const { openMenu, closeMenu, displayMenu, openCartPopup } = useUI();
  const { currentSalonName } = useSettings();
  const {
    layout: { navBarForProfessionals },
    settings: { localesWithWebshop },
  } = globalModules;

  const forProfessionalsMenu = navBarForProfessionals?.menus?.[0];

  const { localWishlist } = useLocalWishlist();
  const router = useRouter();
  const { customer } = useCustomer();
  const [openedSubMenu, setOpenedSubMenu] = useState(false);
  const [salon, setSalon] = useState(currentSalonName);
  const [submenuPosition, setSubmenuPosition] = useState(0);
  const cartContext = useCart();
  const getQuantity = cartContext?.cart?.lines?.edges?.map(item => item.node.quantity);
  const [itemQuantity, setItemQuantity] = useState('');
  const changingSalons = getSelectedSalonNo(locale);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (cartContext.isEmpty) {
      setItemQuantity('');
    } else {
      const reducer = (accumulator, curr) => accumulator + curr;
      setItemQuantity(getQuantity?.reduce(reducer));
    }
  }, [cartContext, setItemQuantity, getQuantity]);

  const handleScroll = () => {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > 1) {
      setOpenedSubMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const preferredSalonNo = getSelectedSalonNo(locale);
    const preferredSalonName = getStorageCustomerSalonName(locale);

    if (preferredSalonNo && preferredSalonName) {
      setSalon(preferredSalonName);
    } else setSalon('');
  }, [router.query, changingSalons]);

  useEffect(() => {
    if (!isMobile) closeMenu();
  }, [isMobile]);

  // To prevent scrolling on body when menu is visible.
  useEffect(() => {
    const body = document.querySelector('body');
    if (displayMenu) {
      body.classList.add(styles.scrollStopper);
    } else {
      body.classList.remove(styles.scrollStopper);
    }
  }, [displayMenu]);

  const calcSubmenuPosition = (e, id) => {
    const { left, width } = e.target.getBoundingClientRect();
    const centerX = left + width / 2;
    const subMenu = document.querySelector(`#submenu_${id}`);
    if (!subMenu) return;
    const subMenuWidth = subMenu.getBoundingClientRect().width;
    let subMenuLeft = centerX - subMenuWidth / 2;
    if (subMenuLeft < 0) subMenuLeft = 0;

    const submenuRight = subMenuLeft + subMenuWidth;
    const windowWidth = window.innerWidth;
    if (submenuRight > windowWidth) {
      subMenuLeft = windowWidth - subMenuWidth;
    }

    setSubmenuPosition(subMenuLeft);
  };

  if (!menu) return null;
  if (!mobileMenu) return null;

  const getExternalLinkWithParams = externalLink => {
    if (externalLink?.includes('salon-finder')) {
      // lcl means locale
      const [lcl, slug] = externalLink?.split('/').filter(Boolean);

      return addLocaleToHref(`/${slug}`, lcl, router);
    }

    return externalLink;
  };

  return (
    <header className={styles.header}>
      <MobileMenu
        locale={locale}
        menu={mobileMenu}
        visible={displayMenu}
        closeMenu={closeMenu}
        mobileMenuSecondary={mobileMenuSecondary}
        localesWithWebshop={localesWithWebshop}
        pageData={pageData}
      />
      <div className={styles.content}>
        {(salon || globalModules['announcement-bar']['announcementBar-AllowedLocales'].onOrOff) && (
          <AnnouncementBar
            salon={salon}
            locale={locale}
            announcementBar={globalModules['announcement-bar']}
            localesWithWebshop={localesWithWebshop}
            is404={is404}
          />
        )}
        <div
          className={cx(styles.navbar, {
            [styles.noAnnouncementBar]:
              !globalModules['announcement-bar']['announcementBar-AllowedLocales'].onOrOff,
          })}
        >
          <div className={cx(styles.mobileFirstColumn)}>
            <button type="button" onClick={() => openMenu(true)}>
              <Icon type="hamburger" className={cx(styles.button, styles.hamburger)} />
            </button>
            <SiteLink
              locale={locale}
              href={addLocaleToHref('/search/', locale, router)}
              prefetch={false}
            >
              <Icon type="search" className={styles.button} />
            </SiteLink>
          </div>
          <div
            className={styles[localesWithWebshop?.onOrOff ? 'columnWithRightHeader' : 'columnLogo']}
          >
            <SiteLink locale={locale} href={addLocaleToHref('/', locale, router)}>
              <Logo className={styles.logo} />
            </SiteLink>
          </div>
          <nav className={styles.columnDesktopNav}>
            <ul className={styles.topLevelLinks}>
              {menu.map(({ title, link, id, menu: subMenu }) => (
                <li key={id || title}>
                  {subMenu ? (
                    <SiteLink
                      locale={locale}
                      href={addLocaleToHref(link.link.slug, locale, router)}
                      external={link?.externalLink}
                      reloaded={link?.pageReload}
                      onMouseEnter={e => {
                        calcSubmenuPosition(e, id);
                        setOpenedSubMenu(id);
                      }}
                      onClick={() => setOpenedSubMenu(false)}
                    >
                      <p>{title}</p>
                    </SiteLink>
                  ) : (
                    <SiteLink
                      locale={locale}
                      onMouseEnter={() => {
                        setOpenedSubMenu(false);
                      }}
                      external={link?.externalLink && getExternalLinkWithParams(link?.externalLink)}
                      href={addLocaleToHref(link?.slug, locale, router)}
                      reloaded={link?.pageReload}
                    >
                      <p>{title}</p>
                    </SiteLink>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <div className={styles.navBarIcons}>
            {navBarForProfessionals && (
              <>
                {forProfessionalsMenu?.menu ? (
                  <SiteLink
                    locale={locale}
                    className={styles.forProfessionalsLink}
                    href={addLocaleToHref(forProfessionalsMenu.link.link.slug, locale, router)}
                    external={forProfessionalsMenu.link?.externalLink}
                    reloaded={forProfessionalsMenu.link?.pageReload}
                    onMouseEnter={e => {
                      calcSubmenuPosition(e, forProfessionalsMenu.id);
                      setOpenedSubMenu(forProfessionalsMenu.id);
                    }}
                    onClick={() => setOpenedSubMenu(false)}
                  >
                    <p>{forProfessionalsMenu.title}</p>
                  </SiteLink>
                ) : (
                  <SiteLink
                    locale={locale}
                    onMouseEnter={() => {
                      setOpenedSubMenu(false);
                    }}
                    className={styles.forProfessionalsLink}
                    external={
                      navBarForProfessionals.link?.externalLink &&
                      getExternalLinkWithParams(navBarForProfessionals.link?.externalLink)
                    }
                    href={addLocaleToHref(navBarForProfessionals.link?.slug, locale, router)}
                    reloaded={navBarForProfessionals.link?.pageReload}
                  >
                    <p>{navBarForProfessionals.link?.title}</p>
                  </SiteLink>
                )}
              </>
            )}

            {/* <SiteLink
              locale={locale}
              className={styles.forProfessionalsLink}
              href={addLocaleToHref(navBarForProfessionals.link.slug, locale, router)}
            >
              {navBarForProfessionals.link.title}
            </SiteLink> */}
            <BelgiumLangSelector locale={locale} pageData={pageData} isDesktop />
            <SiteLink
              locale={locale}
              href={addLocaleToHref('/search/', locale, router)}
              prefetch={false}
              className={cx(styles.button, styles.desktopOnly)}
            >
              <Icon type="search" className={styles.iconHeight} />
            </SiteLink>
            {localesWithWebshop?.onOrOff && (
              <SiteLink
                locale={locale}
                href={addLocaleToHref('/wishlist/', locale, router)}
                prefetch={false}
                className={cx(styles.wishlistIcon)}
              >
                <HeartIcon
                  className={styles.iconHeight}
                  type={localWishlist && localWishlist.length > 0 ? 'black' : ''}
                />
                <span className={styles.quantity}>{localWishlist && localWishlist.length}</span>
              </SiteLink>
            )}
            {localesWithWebshop?.onOrOff && (
              <>
                {customer?.isLoggedIn ? (
                  <SiteLink
                    className={styles.button}
                    locale={locale}
                    href={addLocaleToHref('/account/dashboard/', locale)}
                  >
                    <Icon type="account" className={styles.iconHeight} />
                  </SiteLink>
                ) : (
                  <SiteLink
                    className={styles.button}
                    locale={locale}
                    href={addLocaleToHref('/account/login/', locale)}
                  >
                    <Icon type="account" className={styles.iconHeight} />
                  </SiteLink>
                )}
                <button type="button" className={styles.cartIcon} onClick={() => openCartPopup()}>
                  <Icon type="cart" className={styles.iconHeight} />
                  {!cartContext.isEmpty && (
                    <span className={styles.itemQuantity}>{itemQuantity}</span>
                  )}
                </button>
                {/* <SiteLink
                  locale={locale}
                  href={addLocaleToHref('/cart', locale, router)}
                  className={cx(styles.cartIcon)}
                  prefetch={false}
                >
                  <Icon type="cart" className={styles.iconHeight} />
                  {!cartContext.isEmpty ? (
                    <span className={styles.itemQuantity}>{itemQuantity}</span>
                  ) : (
                    <></>
                  )}
                </SiteLink> */}
              </>
            )}
          </div>
        </div>
      </div>
      {/* Submenus */}
      {[...menu, forProfessionalsMenu || null]
        .filter(Boolean)
        .map(({ id, title, menu: submenu }) => (
          <div key={id || title}>
            {submenu && (
              <nav
                id={`submenu_${id}`}
                className={cx(styles.submenu, {
                  [styles.visible]: id === openedSubMenu,
                  [styles.withTransition]: id !== openedSubMenu,
                  [styles.moreMarginTop]:
                    globalModules['announcement-bar']['announcementBar-AllowedLocales'].onOrOff &&
                    (salon || globalModules['announcement-bar']['announcementBar-Message']?.text),
                })}
                style={{
                  left: submenuPosition,
                }}
              >
                {submenu.map(
                  ({ title: columnTitle, menu: columnMenu }) =>
                    columnMenu?.length > 0 && (
                      <div className={styles.submenuColumn} key={columnTitle}>
                        {columnTitle && <div className={styles.columnTitle}>{columnTitle}</div>}
                        <ul>
                          {columnMenu.filter(Boolean).map(({ title, externalLink, slug }) => (
                            <li key={title}>
                              <SiteLink
                                locale={locale}
                                href={addLocaleToHref(slug, locale, router)}
                                external={externalLink}
                                onClick={() => setOpenedSubMenu(false)}
                              >
                                <p>{title}</p>
                              </SiteLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )
                )}
              </nav>
            )}
          </div>
        ))}
      {/* Click away */}
      <button
        className={cx(styles.clickaway, { [styles.visible]: openedSubMenu })}
        onMouseEnter={() => setOpenedSubMenu(false)}
        aria-label="Close menu"
        type="button"
      >
        Close menu
      </button>
    </header>
  );
};

export default Header;
