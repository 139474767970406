import { Widget, Popover, PopupButton, Sidetab, SliderButton } from '@typeform/embed-react';
import useIsMobile from 'hooks/utils/useIsMobile';
import styles from './typeForm.module.scss';

const TypeForm = ({ data }) => {
  const {
    typeFormId,
    formType,
    buttonText,
    buttonColor,
    buttonTextColor,
    heightDesktop,
    heightMobile,
    widthDesktop,
    widthMobile,
  } = data;

  const defaultButtonColor = '#3c3c3b';
  const defaultButtonTextColor = '#f9f9f9';
  const isMobile = useIsMobile();

  const buttonStyle = {
    padding: '1rem 2rem',
    borderRadius: '5rem',
    border: 'none',
    background: buttonColor?.color || defaultButtonColor,
    color: buttonTextColor?.color || defaultButtonTextColor,
    cursor: 'pointer',
    position: 'fixed',
    fontSize: '1.6rem',
    bottom: 20,
    right: 20,
    zIndex: 9999,
    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
  };

  if (!typeFormId) return null;

  return (
    <>
      {formType === 'Inline' && (
        <section className={styles.container} id="sectionTypeForm">
          <div
            style={{
              width: isMobile ? `${widthMobile || 100}%` : `${widthDesktop || 100}%` || 'auto',
            }}
          >
            <Widget
              id={typeFormId}
              inlineOnMobile
              style={{
                height: isMobile ? heightMobile || 500 : heightDesktop || 600,
              }}
            />
          </div>
        </section>
      )}
      {formType === 'Side tab' && (
        <Sidetab
          id={typeFormId}
          hidden={{ foo: 'foo value', bar: 'bar value' }}
          buttonText={buttonText}
          buttonColor={buttonColor?.color || defaultButtonColor}
          buttonTextColor={buttonTextColor?.color || defaultButtonTextColor}
        />
      )}

      {formType === 'Slider' && (
        <SliderButton id={typeFormId} buttonText={buttonText} style={buttonStyle}>
          {buttonText}
        </SliderButton>
      )}

      {formType === 'Popup' && (
        <PopupButton id={typeFormId} position="right" style={buttonStyle}>
          {buttonText}
        </PopupButton>
      )}

      {formType === 'Popover' && (
        <Popover
          id={typeFormId}
          tooltip={buttonText}
          buttonColor={buttonColor?.color || defaultButtonColor}
          buttonTextColor={buttonTextColor?.color || defaultButtonTextColor}
        />
      )}
    </>
  );
};

export default TypeForm;
