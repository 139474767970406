import Preview from 'components/layout/preview';
import Header from 'components/layout/header';
import Footer from 'components/layout/footer';
import cn from 'classnames';
import TypeForm from 'components/sections/typeForm';
import CartPopup from './cartPopup';
import styles from './layout.module.scss';
import SalonSelectorModal from './salonSelectorModal';
import ConfirmWishlistPopup from './confirmWishlistPopup';

const Layout = ({ children, locale, previewData, globalModules, pageData, is404 }) => {
  const hasAnnouncementBar =
    globalModules['announcement-bar']['announcementBar-AllowedLocales']?.onOrOff;

  const { hideLayout } = pageData || {};
  const typeFormSettings = globalModules?.secondpartsettings?.embedTypeform?.sections?.[0];

  return (
    <>
      {hideLayout ? null : (
        <Header
          locale={locale}
          menu={globalModules?.layout?.navbar?.menus}
          mobileMenu={globalModules?.layout?.navbarMobile?.menus}
          mobileMenuSecondary={globalModules?.layout?.navbarMobileBottom?.menus}
          globalModules={globalModules}
          is404={is404}
          pageData={pageData}
        />
      )}
      <main className={cn(styles.main, { [styles.noAnnouncementBar]: !hasAnnouncementBar })}>
        {children}
      </main>
      {hideLayout ? null : (
        <Footer locale={locale} globalModules={globalModules} pageData={pageData} is404={is404} />
      )}
      {previewData && <Preview message={previewData.message} />}
      {typeFormSettings?.typeFormId && <TypeForm data={typeFormSettings} />}
      <CartPopup globalModules={globalModules} locale={locale} />
      <SalonSelectorModal globalModules={globalModules} locale={locale} />
      <ConfirmWishlistPopup globalModules={globalModules} />
    </>
  );
};
export default Layout;
